// BackButton.js
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './BackButton.css'; // Opcional: para estilizar o botão

const BackButton = () => {
  const navigate = useNavigate();
  const {id} = useParams()

  const handleClick = () => {
    navigate(`/Principal/${id}`); // Redireciona para a página principal
  };

  return (
    <button className="back-button" onClick={handleClick}>
      Voltar
    </button>
  );
};

export default BackButton;
