import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import './serviçoemandamento.css';

const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br'); // Substitua pela URL correta do seu servidor

const ServicoEmAndamento = () => {
  const { id } = useParams(); // Pega o ID da URL
  const [serviceDetails, setServiceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos/${id}`);
        console.log(response.data)
        setServiceDetails(response.data);
      } catch (err) {
        setError('Erro ao carregar os detalhes do serviço.');
      } finally {
        setLoading(false);
      }
    };

    fetchServiceDetails();
  }, [id]);


  useEffect(() => {
    socket.on('status-pedido-atualizado', ({ id: pedidoId, status }) => {
      if (pedidoId === Number(id) && status === 'Concluído') {
        navigate(`/payment-gateway/${id}`);
      }
    });

    return () => {
      socket.off('status-pedido-atualizado');
    };
  }, [id, navigate]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!serviceDetails) {
    return <div>Nenhum detalhe de serviço encontrado.</div>;
  }

  return (
    <div className="ongoing-service-container">
      <h1>Serviço em Andamento</h1>
      <div id="service-details" className="service-details">
        <div className="service-item">
          <h2>{serviceDetails.nome_servico}</h2>
          <p><strong>Categoria:</strong> {serviceDetails.categoria}</p>
          <p><strong>Preço:</strong> {serviceDetails.preco}</p>
          <p><strong>Cliente:</strong> {serviceDetails.cliente}</p>
          <p><strong>Prestador:</strong> {serviceDetails.provider}</p>
          <p><strong>Status:</strong> {serviceDetails.status}</p>
          <div className="loading-bar-container">
            <div className="loading-bar"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicoEmAndamento;
