import React from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from './pages/welcome/bemvindo';
import Login from './pages/login/login';
import Cadastro from './pages/cadastro/cadastro';
import ClientProfile from './pages/perfilcliente/perfilcliente';
import MapaPage from './pages/mapapage/mapapage';
import ServicoEmAndamento from './pages/serviçoandamentocliente/serviçoandamentocliente';

import CadastroP from './pagesPrestador/cadastro/cadastro';
import LoginPrestadores from './pagesPrestador/LoginP/LoginP';
import Principal from './pagesPrestador/principal/principal';
import Profile from './pagesPrestador/perfil/perfil';
import ServicosProximos from './pagesPrestador/serviçosproximos/serviçosproximos';
import ServiceInProgress from './pagesPrestador/serviçoemandamento/ServiceInProgress';
import Faturamento from './pagesPrestador/faturamento/faturamento';
import AdminDashboard from './admpages/principaladm/principaladm';
import ProvidersManagement from './admpages/adicionarosprestadores/ProvidersManagement';
import ConsumersManagement from './admpages/adicionarconsumidores/ConsumersManagement';
import ServicesManagement from './admpages/adicionarserviços/ServicesManagement';
import ServiceRequest from './pages/ServiceSearch/ServiceSearch';
import AguardandoAceitacao from './pages/AwaitingAcceptance/AwaitingAcceptance';
import PaymentGateway from './pages/Paymentsgetway/Paymentsgetway';
import AguardandoPagamento from './pagesPrestador/aguardandopagamento/aguardandopagamento';
import AvaliacaoCliente from './pagesPrestador/avaliarcliente/avaliarcliente';
import AvaliacaoPrestador from './pages/avaliarprestador/avaliarprestador';
import Serviconaoterminado from './pagesPrestador/serviçosnãoterminados/serviçonaoterminado';
import ServiconaoterminadoC from './pages/servicosnaoterminadosclientes/servicosnaoterminadosclientes';
import CalendarioAgendamentos from './pages/telaagendamentocliente/teladeagendamentocliente';
import CalendarioAgendamentosP from './pagesPrestador/teladeagendamentoprestador/teladeagendamentoprestador';
import PrivateRoute from './components/PrivateRoutes';
import PrivateRouteP from './components/PrivateRoutesP';
import PaymentCompleted from './pages/Paymentsgetway/PaymentCompleted';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        /*Telas prestador*/
        <Route path="/CadastroP" element={<CadastroP />} />
        <Route path="/LoginP" element={<LoginPrestadores />} />
        <Route path='/Principal/:id' element={<Principal/>}  />
        <Route path='/Profile/:id' element={<Profile/>} />
        <Route path="/faturamento/:id" element={<Faturamento/>}  />
        <Route path='/ServiçosProximos/:id' element={<ServicosProximos/>}  />
        <Route path='/servico-em-andamento/:id' element={<ServiceInProgress/>} />
        <Route path="/aguardando-pagamento/:id" element={<AguardandoPagamento/>} />
        <Route path="/avaliarcliente/:id" element={<AvaliacaoCliente/>} />
        <Route path='/servicosemandamentos/:id' element={<Serviconaoterminado/>} />
        <Route path='/AgendaPrestador/:id' element={<CalendarioAgendamentosP/>} />
        
        /*Telas Consumidor*/
        <Route path="/login" element={<Login />} />
        <Route path="/Cadastro" element={<Cadastro />} />
        <Route path="/MapaPage/:id" element={<MapaPage />} />
        <Route path="/Serviços/:id" element={<ServiceRequest />}/>
        <Route path='/aguardando-aceitacao/:id' element={<AguardandoAceitacao/>} />
        <Route path="/serviçoemandamento/:id" element={<ServicoEmAndamento/>} />
        <Route path="/ClienteProfile/:id" element={<ClientProfile/>}  />
        <Route path='/Agenda/:id' element={<CalendarioAgendamentos/>} />
        <Route path='/payment-gateway/:id' element={<PaymentGateway/>}/>
        <Route path='confirmar-pagamento/:id' element={<PaymentCompleted/>}/>
        <Route path='/avaliarPrestador/:id' element={<AvaliacaoPrestador/>}/>   
        <Route path='/servicosemandamentosclientes/:id' element={<ServiconaoterminadoC/>} />   
        /*Telas do Administrador */
        <Route path='/Principal-Administrador'element={<AdminDashboard/>}/>
        <Route path='/ProvidersManagement'element={<ProvidersManagement/>}/>
        <Route path='/ConsumersManagement'element={<ConsumersManagement/>}/>
        <Route path='/ServicesManagement'element={<ServicesManagement/>}/>

      </Routes>
    </Router>
  );
}

export default App;
