import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './getway.css';

const PaymentCompleted = () =>{
    const [paymentMethod, setPaymentMethod] = useState('credit-debit-card');
    const [serviceValue, setServiceValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [idprestador, Setidprestador] = useState(null);
    const [nomeprestador, Setnomeprestador] = useState(null);

    useEffect(() => {
        const fetchServiceValue = async () => {
            try {
                const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos/${id}`);
                const preco = parseFloat(response.data.preco); // Garantir que o valor seja um número
                const idprestador = response.data.idprestador
                const prestador = response.data.provider
                setServiceValue(preco);
                Setidprestador(idprestador);
                Setnomeprestador(prestador);
                return;
            } catch (err) {
                setError('Erro ao carregar o valor do serviço.');
            } finally {
                setLoading(false);
            }
        };

        fetchServiceValue();
    }, [id]);

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!id) {
            alert('ID do serviço não encontrado.');
            return;
        }

        try {
            await axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/atualizar-pedido', {
                id: id,
                status: 'Pago',
                idprestador: idprestador,
                provider: nomeprestador,
            });

            alert('Pagamento confirmado!');
            navigate(`/avaliarPrestador/${id}`);
        } catch (error) {
            console.error('Erro ao confirmar o pagamento:', error);
            alert('Erro ao confirmar o pagamento.');
        }
    };

    if (loading) {
        return <div className="loading">Carregando...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }


    return(
        <main>
            <h1>Pagamento Concluído</h1>
            <button className='buttong' onClick={handleSubmit}>Ir para tela de avaliação</button>
        </main>
    )
}

export default PaymentCompleted;