import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './perfilcliente.css';  // Certifique-se de ajustar o caminho conforme necessário
import { useParams } from 'react-router-dom';
import BackButton from '../../components/botãodevoltar';
import Simplebutton from '../../components/simplebutton';
import TermoCliente from '../cadastro/Termo';

const ClienteProfile = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [profileImage, setProfileImage] = useState('/uploads/avatar.png');
  const [mediaAvaliacoes, setMediaAvaliacoes] = useState(0);
  const [isModal, setIsModal] = useState(false)
  const [isModal2, setIsModal2] = useState(false)
  const [isModal3, setIsModal3] =useState(false)

  const handleModal = () =>{
    setIsModal((prev) => !prev)
  }

  const handleModal2 = () =>{
    setIsModal2((prev) => !prev)
  }

  const deleteAccount = async () =>{
    try {
      const response = await axios.post(`https://appdev.chamaseuze.chamaoseuze.com.br/DeleteProfileUser/${id}`)
      setIsModal3(true)
    } catch (error) {
      console.log(error.response.data.message)
    }
  }


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/cliente/${id}`);
        setUserData(response.data);
        if (response.data.profileImage) {
          setProfileImage(`https://appdev.chamaseuze.chamaoseuze.com.br/${response.data.profileImage}`);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do cliente:', error);
      }
    };

    const fetchMediaAvaliacoes = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/avaliacoes/cliente/${id}/media`);
        setMediaAvaliacoes(response.data.mediaAvaliacoes);
      } catch (error) {
        console.error('Erro ao buscar média das avaliações:', error);
      }
    };
    

    fetchUserData();
    fetchMediaAvaliacoes();
  }, [id]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('profileImage', file);

    try {
      const response = await axios.post(`https://appdev.chamaseuze.chamaoseuze.com.br/upload/cliente/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setProfileImage(`https://appdev.chamaseuze.chamaoseuze.com.br/${response.data.profileImage}`);
    } catch (error) {
      console.error('Erro ao enviar imagem:', error);
    }
  };

  if (!userData) return null;

  return (
    <div className="profile-container">
      <BackButton /> <Simplebutton onClick={handleModal} style={{weigth:'600', padding:'12px 20px'}}>{'Configuração'}</Simplebutton>
      {isModal &&(
        <div className='modal'>
          <div className='modal-content' style={{display:'flex',flexDirection: 'column', position: 'fixed', left: 0, height:'100%'}}>
            <Simplebutton onClick={handleModal} style={{width: '30px'}}>x</Simplebutton>
            <h2>
              Configurações
            </h2>
            <Simplebutton onClick={handleModal2}>Termos de uso e privacidade</Simplebutton>
            {isModal2 &&(
              <div className="modal" style={{alignItems: 'baseline'}}>
                <div className="modal-content" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                  <div className="termos">
                    <TermoCliente/>
                  </div>
                  <Simplebutton onClick={handleModal} style={{width:'200px'}}>Concluir</Simplebutton>
                </div>
              </div>
            )}

            <Simplebutton onClick={deleteAccount} style={{backgroundColor: 'red'}}>Excluir Conta</Simplebutton>
            {isModal3 &&(
              <div className="modal">
                <div className="modal-content">
                  <h2>Conta excluida com sucesso!</h2>
                  <a href="/">Ok</a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <h1>Meu Perfil</h1>
      <div className="profile">
        <div className="profile-info">
          <div className="profile-img">
            <img id="profile-image" src={profileImage} alt="Foto de Perfil" />
            <input
              type="file"
              id="upload-photo-btn"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="upload-photo-btn" className="upload-btn">Alterar Foto</label>
          </div>
          <div className="profile-details">
            <h2>{userData.nome}</h2>
            <p>E-mail: {userData.email}</p>
            <p>Telefone: {userData.telefone}</p>
            <p>Endereço: {userData.endereco}</p>
            <p>Média das Avaliações: {mediaAvaliacoes.toFixed(2)}</p> {/* Exibindo a média das avaliações */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClienteProfile;
