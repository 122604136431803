import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AwaitingAcceptance.css';
import io from 'socket.io-client';

const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br'); // Substitua pela URL correta do seu servidor

const AguardandoAceitacao = () => {
  const { id } = useParams(); // Pega o ID da URL
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPedido = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos/${id}`);
        setPedido(response.data);
      } catch (err) {
        setError('Erro ao carregar o pedido.');
      } finally {
        setLoading(false);
      }
    };
    fetchPedido();
  }, [id]);

  useEffect(() => {
    socket.on('status-pedido-atualizado', ({ id: pedidoId, status }) => {
      if (pedidoId === Number(id) && status === 'Aceito') {
        navigate(`/serviçoemandamento/${id}`);
      }
    });
    return () => {
      socket.off('status-pedido-atualizado');
    };
  }, [id, navigate]);

  if (loading) {
    return <div className="awaiting-acceptance">Carregando...</div>;
  }

  if (error) {
    return <div className="awaiting-acceptance">{error}</div>;
  }

  if (!pedido) {
    return <div className="awaiting-acceptance">Nenhum pedido encontrado.</div>;
  }

  return (
    <div className="awaiting-acceptance">
      <h1>Aguardando Aceitação do Pedido</h1>
      <div className="pedido-details">
        <p><strong>ID do Pedido:</strong> {pedido.id}</p>
        <p><strong>Nome do Serviço:</strong> {pedido.nome_servico}</p>
        <p><strong>Categoria:</strong> {pedido.categoria}</p>
        <p><strong>Preço:</strong> {pedido.preco}</p>
        <p><strong>Cliente:</strong> {pedido.cliente}</p>
        <p><strong>Status:</strong> {pedido.status}</p>
      </div>
    </div>
  );
};

export default AguardandoAceitacao;