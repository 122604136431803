// src/components/ConsumersManagement.js

import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './ConsumersManagement.css'; // Arquivo CSS para estilos

const ConsumersManagement = () => {
  const [consumers, setConsumers] = useState([]);
  const [selectedConsumer, setSelectedConsumer] = useState(null);

  useEffect(() => {
    // Simulação de dados (substitua com dados reais de uma API)
    setConsumers([
      { id: 1, name: 'Ana Costa', email: 'ana@example.com' },
      { id: 2, name: 'Pedro Almeida', email: 'pedro@example.com' },
      { id: 3, name: 'Laura Martins', email: 'laura@example.com' },
    ]);
  }, []);

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Adicionar os dados importados à lista de consumidores
      setConsumers((prevConsumers) => [
        ...prevConsumers,
        ...jsonData.map((item, index) => ({ id: prevConsumers.length + index + 1, ...item })),
      ]);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleEdit = () => {
    if (!selectedConsumer) {
      alert('Selecione um consumidor para editar.');
      return;
    }
    // Função para editar um consumidor
    console.log(`Editar consumidor com ID: ${selectedConsumer.id}`);
  };

  const handleDelete = () => {
    if (!selectedConsumer) {
      alert('Selecione um consumidor para excluir.');
      return;
    }
    // Função para excluir um consumidor
    setConsumers((prevConsumers) =>
      prevConsumers.filter((consumer) => consumer.id !== selectedConsumer.id)
    );
    setSelectedConsumer(null);
  };

  const handleSelectConsumer = (id) => {
    setSelectedConsumer(consumers.find((consumer) => consumer.id === id));
  };

  return (
    <div className="consumers-management">
      <header>
        <h1>Gestão de Consumidores</h1>
      </header>
      <main>
        <div className="header-buttons">
          <label>
            <input
              type="file"
              accept=".xlsx"
              style={{ display: 'none' }}
              onChange={handleImport}
            />
            <button onClick={() => document.querySelector('input[type="file"]').click()}>
              Incluir
            </button>
          </label>
          <button onClick={handleEdit}>Alterar</button>
          <button onClick={handleDelete}>Excluir</button>
        </div>
        <section className="consumers-list">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {consumers.map((consumer) => (
                <tr
                  key={consumer.id}
                  className={selectedConsumer?.id === consumer.id ? 'selected' : ''}
                  onClick={() => handleSelectConsumer(consumer.id)}
                >
                  <td>{consumer.id}</td>
                  <td>{consumer.name}</td>
                  <td>{consumer.email}</td>
                  <td>
                    <button onClick={() => handleEdit(consumer.id)}>Alterar</button>
                    <button onClick={() => handleDelete(consumer.id)}>Excluir</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default ConsumersManagement;
