import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import io from 'socket.io-client';

const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br'); // Substitua pela URL correta do seu servidor

const AguardandoPagamento = () => {
  const { id } = useParams(); // Pega o ID da URL
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPedido = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos/${id}`);
        setPedido(response.data);
      } catch (err) {
        setError('Erro ao carregar o pedido.');
      } finally {
        setLoading(false);
      }
    };

    fetchPedido();
  }, [id]);

  useEffect(() => {
    socket.on('status-pedido-atualizado', ({ id: pedidoId, status }) => {
      if (Number(pedidoId) === Number(id) && status === 'Pago') {
        navigate(`/avaliarcliente/${id}`);
      }
    });

    return () => {
      socket.off('status-pedido-atualizado');
    };
  }, [id, navigate]);

  if (loading) {
    return <Loading>Carregando...</Loading>;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  if (!pedido) {
    return <Error>Nenhum detalhe de serviço encontrado.</Error>;
  }

  return (
    <Container>
      <Header>Aguardando Pagamento</Header>
      <Content>
        <ServiceDetails>
          <h2>{pedido.nome_servico}</h2>
          <p><strong>Categoria:</strong> {pedido.categoria}</p>
          <p><strong>Preço:</strong> {pedido.preco}</p>
          <p><strong>Cliente:</strong> {pedido.cliente}</p>
        </ServiceDetails>
        <PaymentStatus>
          <StatusText>Aguardando pagamento do cliente...</StatusText>
          <Spinner />
        </PaymentStatus>
      </Content>
    </Container>
  );
};

export default AguardandoPagamento;

// Styled Components

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #001f3f;
  color: #ffffff;
`;

const Header = styled.header`
  width: 100%;
  padding: 20px;
  background-color: #ff6600;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ServiceDetails = styled.div`
  background-color: #003366;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
  h2 {
    margin-top: 0;
  }
`;

const PaymentStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusText = styled.p`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #ff6600;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 2s linear infinite;
`;

const Loading = styled.div`
  font-size: 1.5rem;
  color: #ff6600;
`;

const Error = styled.div`
  font-size: 1.5rem;
  color: #ff6600;
`;
