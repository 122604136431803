import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import './telaagendamento.css';
import BackButton from '../../components/botãodevoltar';

const CalendarioAgendamentos = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newEvent, setNewEvent] = useState({ date: '', time: '', nomeServico: '', categoria: '' });
  const [userData, setUserData] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null); // Novo estado para o evento selecionado
  const [showEventModal, setShowEventModal] = useState(false); // Novo estado para controlar o modal de detalhes do evento
  const navigate = useNavigate();
  const { id } = useParams(); // Obtendo o id da URL

  useEffect(() => {
    // Função para buscar os dados do cliente
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/cliente/${id}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados do cliente:', error);
      }
    };

    // Função para buscar eventos existentes
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/obter-pedidos/${id}`);
        const fetchedEvents = response.data.map((pedido) => ({
          title: pedido.nome_servico,
          start: pedido.date, // FullCalendar deve ser capaz de lidar com o formato ISO 8601
          extendedProps: {
            id: pedido.id,
            categoria: pedido.categoria,
            preco: pedido.preco,
            cliente: pedido.cliente,
            status: pedido.status,
            endereco: pedido.endereco,
            numero: pedido.numero,
          },
        }));
        setEvents(fetchedEvents);
      } catch (error) {
        console.error('Erro ao carregar eventos:', error);
      }
    };

    fetchUserData();
    fetchEvents();
  }, [id]);

  useEffect(() => {
  }, [events]);

  // Função para salvar o novo pedido
  const handleSaveEvent = async (e) => {
    e.preventDefault();
    const { date, time, nomeServico, categoria } = newEvent;
    try {
      const response = await axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/criar-pedido', {
        nome_servico: nomeServico,
        categoria,
        preco: 50, // Ajuste conforme necessário
        cliente: userData.nome,
        idcliente: id,
        status: 'Pendente',
        endereco: userData.endereco,
        numero: userData.numeroCasa,
        date,
        time,
      });

      const newEventObject = {
        title: nomeServico,
        start: `${date}T${time}`,
        extendedProps: {
          id: response.data.id,
          categoria,
          preco: 50,
          cliente: userData.nome,
          status: 'Pendente',
          endereco: userData.endereco,
          numero: userData.numeroCasa,
        },
      };

      setEvents([...events, newEventObject]);
      setShowModal(false);
      navigate(`/MapaPage/${id}`);
      alert('Pedido de serviço criado com sucesso!');
    } catch (error) {
      console.error('Erro ao criar pedido de serviço:', error);
    }
  };

  // Abrir o modal para criar um novo evento
  const handleDateClick = (arg) => {
    setNewEvent({ ...newEvent, date: arg.dateStr });
    setShowModal(true);
  };

  // Fechar o modal
  const handleCloseModal = () => setShowModal(false);

  // Função para lidar com o clique no evento
  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event.extendedProps);
    setShowEventModal(true);
  };

  // Fechar o modal de detalhes do evento
  const handleCloseEventModal = () => setShowEventModal(false);

  return (
    <div className="calendar-container">
      <div className="alinhamento">
        <h2>Agendamentos</h2>
        <BackButton/>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick} // Adicionando o manipulador de clique no evento
      />

      {/* Modal para criar um novo evento */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Agendar Serviço</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSaveEvent}>
            <Form.Group>
              <Form.Label>Data</Form.Label>
              <Form.Control
                type="date"
                value={newEvent.date}
                onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
                required
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Hora</Form.Label>
              <Form.Control
                type="time"
                value={newEvent.time}
                onChange={(e) => setNewEvent({ ...newEvent, time: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nome do Serviço</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome do Serviço"
                value={newEvent.nomeServico}
                onChange={(e) => setNewEvent({ ...newEvent, nomeServico: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Categoria</Form.Label>
              <Form.Control
                as="select"
                value={newEvent.categoria}
                onChange={(e) => setNewEvent({ ...newEvent, categoria: e.target.value })}
                required
              >
                <option value="">Selecione a Categoria</option>
                <option value="Eletricista">Eletricista</option>
                <option value="Encanador">Encanador</option>
                <option value="Marceneiro">Marceneiro</option>
                <option value="Pintor">Pintor</option>
                <option value="Pedreiro">Pedreiro</option>
              </Form.Control>
            </Form.Group>
            <div className='btnModal'>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
              <Button variant="secondary" onClick={handleCloseModal} className="ml-2">
                Fechar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal para exibir os detalhes do evento */}
      <Modal show={showEventModal} onHide={handleCloseEventModal}>
        <Modal.Header>
          <Modal.Title>Detalhes do Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <div>
              <p><strong>Nome do Serviço:</strong> {selectedEvent.nome_servico}</p>
              <p><strong>Categoria:</strong> {selectedEvent.categoria}</p>
              <p><strong>Preço:</strong> {selectedEvent.preco}</p>
              <p><strong>Cliente:</strong> {selectedEvent.cliente}</p>
              <p><strong>Status:</strong> {selectedEvent.status}</p>
              <p><strong>Endereço:</strong> {selectedEvent.endereco}</p>
              <p><strong>Número:</strong> {selectedEvent.numero}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEventModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CalendarioAgendamentos;