import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import './ServiceInProgress.css';

const ServiceInProgress = () => {
  const { id } = useParams(); // Obtém o ID do pedido da URL
  const [service, setService] = useState(null);
  const navigate = useNavigate();
  const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br');

  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos/${id}`);
        setService(response.data);
        console.log(response.data.date)
      } catch (error) {
        console.error('Erro ao carregar os detalhes do serviço:', error);
      }
    };
    fetchService();
  }, [id]); 

  const compareDates = (date1, date2) => {
    const d1 = new Date(date1).toISOString().split('T')[0];
    const d2 = new Date(date2).toISOString().split('T')[0];
    return d1 === d2;
};

  const handleComplete = async () => {
    if (!service) {
      alert('Serviço não encontrado.');
      return;
    }

    const currentDate = new Date();
    const serviceDate = new Date(service.date);

    if (!compareDates(currentDate, serviceDate)) {
      alert('A data atual não coincide com a data do pedido.');
      return;
    }

    try {
      // Atualiza o status do pedido no banco de dados
      await axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/atualizar-pedido', {
        id: service.id,
        status: 'Concluído',
        provider: service.provider,
        idprestador: service.idprestador
      });

      // Emite um evento para notificar a conclusão
      socket.emit('status-pedido-atualizado', { id: service.id, status: 'Concluído' });

      alert('Serviço concluído!');
      navigate(`/aguardando-pagamento/${service.id}`); // Navegar para a tela de aguardando pagamento do prestador
    } catch (error) {
      console.error('Erro ao atualizar status do serviço:', error);
    }
  };

  if (!service) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="service-info">
      <h2>Serviço em Andamento</h2>
      <p><strong>Cliente:</strong> {service.cliente}</p>
      <p><strong>Serviço:</strong> {service.nome_servico}</p>
      <p><strong>Endereço:</strong> {service.endereco},{service.numero}</p>
      <p><strong>Status:</strong> Em Andamento</p>
      <div className="button-container">
        <button className="btn" onClick={handleComplete}>Concluir Serviço</button>
      </div>
    </div>
  );
};

export default ServiceInProgress;