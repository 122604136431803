import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import './telaagendamento.css';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import BackButton from '../../components/botãodevoltarP';

const CalendarioAgendamentosP = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [userData, setUserData] = useState(null);
  
  const { id } = useParams(); // Obtendo o id da URL

  useEffect(() => {
    // Função para buscar os dados do cliente
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/prestador/${id}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados do cliente:', error);
      }
    };

    // Função para buscar eventos existentes
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/obter-pedidosp/${id}`);
        const fetchedEvents = response.data.map((pedido) => ({
          title: pedido.nome_servico,
          start: pedido.date, // FullCalendar deve ser capaz de lidar com o formato ISO 8601
          extendedProps: {
            id: pedido.id,
            categoria: pedido.categoria,
            preco: pedido.preco,
            cliente: pedido.cliente,
            status: pedido.status,
            endereco: pedido.endereco,
            numero: pedido.numero,
            date: pedido.date,
            time: pedido.time
          },
        }));
        setEvents(fetchedEvents);
      } catch (error) {
        console.error('Erro ao carregar eventos:', error);
      }
    };

    fetchUserData();
    fetchEvents();
  }, [id]);

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd/MM/yyyy', { locale: ptBR });
  };

  useEffect(() => {
  }, [events]);

  // Função para abrir o modal com os detalhes dos pedidos do dia
  const handleDateClick = (arg) => {
    const eventsOnSelectedDate = events.filter(event => event.start.startsWith(arg.dateStr));
    setSelectedDateEvents(eventsOnSelectedDate);
    setShowModal(true);
  };

  // Fechar o modal
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="calendar-container">
      <div className="alinhamento">
        <h2>Agendamentos</h2>
        <BackButton/>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={handleDateClick}
      />
      {/* Modal para mostrar os detalhes dos pedidos */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Detalhes dos Pedidos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDateEvents.length > 0 ? (
            selectedDateEvents.map((event, index) => (
              <div key={index}>
                <p><strong>Serviço:</strong> {event.title}</p>
                <p><strong>Categoria:</strong> {event.extendedProps.categoria}</p>
                <p><strong>Preço:</strong> {event.extendedProps.preco}</p>
                <p><strong>Cliente:</strong> {event.extendedProps.cliente}</p>
                <p><strong>Status:</strong> {event.extendedProps.status}</p>
                <p><strong>Endereço:</strong> {event.extendedProps.endereco}, {event.extendedProps.numero}</p>
                <p><strong>Data:</strong> {formatDate(event.extendedProps.date)}</p>
                <p><strong>Hora:</strong> {event.extendedProps.time}</p>
                <hr />
              </div>
            ))
          ) : (
            <p>Não há pedidos para esta data.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CalendarioAgendamentosP;