import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './getway.css';

const PaymentGateway = () => {
    const [paymentMethod, setPaymentMethod] = useState('credit-debit-card');
    const [serviceValue, setServiceValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true); // Estado de carregamento do usuário
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [nameService, setNameService] = useState(null);
    const [idprestador, Setidprestador] = useState(null);
    const [nomeprestador, Setnomeprestador] = useState(null);
    const [idcliente, setIdCliente] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchServiceValue = async () => {
            try {
                const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/pedidos/${id}`);
                const preco = parseFloat(response.data.preco);
                const idprestador = response.data.idprestador;
                const prestador = response.data.provider;
                const idcliente = response.data.idcliente;
                const service = response.data.nome_servico;

                setNameService(service);
                setIdCliente(idcliente);
                setServiceValue(preco);
                Setidprestador(idprestador);
                Setnomeprestador(prestador);

                // Fetch Cliente após definir o idcliente
                await fetchCliente(idcliente);
            } catch (err) {
                setError('Erro ao carregar o valor do serviço.');
            } finally {
                setLoading(false);
            }
        };

        const fetchCliente = async (id) => {
            setUserLoading(true); // Ativa o carregamento do usuário
            try {
                const response = await axios.get(`https://appdev.chamaseuze.chamaoseuze.com.br/cliente/${id}`);
                const user = response.data;
                setUser(user);
            } catch (error) {
                setError('Erro ao carregar dados do cliente.');
            } finally {
                setUserLoading(false); // Desativa o carregamento do usuário
            }
        };
        fetchServiceValue();
    }, [id]);

    const handleSubmit = async () => {
        if (!user) {
            alert('Informações do usuário não carregadas. Por favor, tente novamente.');
            return;
        }

        const formData = {
            name: user.nome,
            email: user.email,
            taxId: user.cpf,
        };

        const item = {
            reference_id: id,
            name: nameService,
            quantity: '1',
            unit_amount: serviceValue,
        };

        const payload = {
            formData,
            item,
        };

        try {
            const response = await fetch('https://appdev.chamaseuze.chamaoseuze.com.br/PaymentController', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const data = await response.json();

            if (response.ok) {
                window.location.href = data.paymentUrl;
            } else {
                console.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Erro ao enviar dados.');
        }
    };

    return (
        <div className="payment-container">
            {loading || userLoading ? ( // Verifica se qualquer carregamento está ativo
                <p>Carregando...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <button 
                    className='buttong' 
                    onClick={handleSubmit} 
                    disabled={userLoading} // Desabilita o botão se o usuário ainda não foi carregado
                >
                    Pagar
                </button>
            )}
        </div>
    );
};

export default PaymentGateway;
