import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';
import '@fortawesome/fontawesome-free/css/all.css';
import './principal.css';
import { useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';

const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br/');

const Principal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [userPosition, setUserPosition] = useState(null);

  function perfil() {
    navigate(`/Profile/${id}`);
  }
  function faturamento() {
    navigate(`/faturamento/${id}`);
  }
  function Agenda() {
    navigate(`/AgendaPrestador/${id}`);
  }
  function buscar() {
    navigate(`/ServiçosProximos/${id}`);
  }

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const pos = await getCurrentLocation();
        const coords = [pos.coords.latitude, pos.coords.longitude];
        setUserPosition(coords);


        // Enviar localização do prestador para o servidor
        socket.emit('update-location', {
          id, // Usando o ID da URL
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        });
      } catch (error) {
        console.error('Erro ao obter localização do prestador:', error.message);
      }
    };

    fetchUserLocation();

    return () => {
      socket.off('location-updated');
    };
  }, [id]);

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error('Geolocalização não suportada pelo navegador.'));
      }
    });
  };

  useEffect(() => {
    if (userPosition) {
      if (mapRef.current) {
        mapRef.current.off();
        mapRef.current.remove();
        mapRef.current = null;
      }

      mapRef.current = L.map('map').setView(userPosition, 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      }).addTo(mapRef.current);

      const userIcon = L.AwesomeMarkers.icon({
        icon: 'fa-location-arrow',
        markerColor: 'blue',
        prefix: 'fa'
      });

      L.marker(userPosition, { icon: userIcon }).addTo(mapRef.current)
        .bindPopup('Você está aqui!')
        .openPopup();
    }
  }, [userPosition]);

  return (
    <div className="map-container">
      <div id="map" style={{ height: '90dvh' }}></div>
      <div className='content'>
      <button className='butn' onClick={Agenda}>
          <i className="fas fa-calendar-alt"></i> Agenda
        </button>
        <button className='butn' onClick={buscar}>
          <i className="fas fa-map-marker-alt"></i> Serviços Próximos
        </button>
        <button className='butn' onClick={perfil}>
          <i className="fas fa-user"></i> Perfil
        </button>
        <button className='butn' onClick={faturamento}>
          <i className="fas fa-dollar-sign"></i> Faturamento
        </button>
      </div>
    </div>
  );
};

export default Principal;
