// src/components/AdminDashboard.js

import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import './AdminDashboard.css'; // Arquivo CSS para estilos
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  // Estado para armazenar os dados
  const [providers, setProviders] = useState([]);
  const [consumers, setConsumers] = useState([]);

  useEffect(() => {
    // Simulação de dados (substitua com dados reais de uma API)
    setProviders([
      { name: 'João da Silva', online: true },
      { name: 'Maria Oliveira', online: true },
      { name: 'José Santos', online: true },
    ]);
    setConsumers([
      { name: 'Ana Paula', online: true },
      { name: 'Roberto Lima', online: true },
      { name: 'Fernanda Costa', online: true },
    ]);
  }, []);

  // Dados para o gráfico
  const data = [
    { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
  ];

  const navigate = useNavigate()

  function prestador(){
    navigate('/ProvidersManagement')
  }
  function consumidor(){
    navigate('/ConsumersManagement')
  }
  function Serviços(){
    navigate('/ServicesManagement')
  }

  return (
    <div className="dashboard-container">
      <header>
        <h1 className='dash'>Dashboard Administrador</h1>
      </header>
      <main>
        <section className="dashboard">
          <h2>Dashboard</h2>
          <div className="dashboard-content">
            <LineChart width={600} height={300} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="uv" stroke="#003366" />
              <Line type="monotone" dataKey="pv" stroke="#ff7300" />
            </LineChart>
          </div>
        </section>
        <section className="online-list">
          <div className="section-header">
            <h2>Prestadores Online</h2>
            <button className="navigate-btn" onClick={prestador}>Ver Todos</button>
          </div>
          <div className="status-indicator"></div>
          <ul>
            {providers.map((provider, index) => (
              <li key={index}>
                {provider.name} <span className={`status ${provider.online ? 'online' : 'offline'}`}></span>
              </li>
            ))}
          </ul>
        </section>
        <section className="consumer-list">
          <div className="section-header">
            <h2>Consumidores Online</h2>
            <button className="navigate-btn" onClick={consumidor}>Ver Todos</button>
          </div>
          <div className="status-indicator"></div>
          <ul>
            {consumers.map((consumer, index) => (
              <li key={index}>
                {consumer.name} <span className={`status ${consumer.online ? 'online' : 'offline'}`}></span>
              </li>
            ))}
          </ul>
        </section>
        <footer>
          <button className="btn">Relatórios</button>
          <button className="btn">Transações</button>
          <button className="btn" onClick={Serviços}>Serviços</button>
        </footer>
      </main>
    </div>
  );
};

export default AdminDashboard;
