// src/pages/Faturamento.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale } from 'chart.js';
import './Faturamento.css';
import './chartConfig';
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale);

const Faturamento = () => {
  const [faturamentoDia, setFaturamentoDia] = useState(0);
  const [faturamentoMes, setFaturamentoMes] = useState(0);
  const [faturamentoUltimosMeses, setFaturamentoUltimosMeses] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Substitua pelos endpoints reais
        const responseDia = await axios.get('http://localhost:3001/faturamento-dia');
        const responseMes = await axios.get('http://localhost:3001/faturamento-mes');
        const responseUltimosMeses = await axios.get('http://localhost:3001/faturamento-ultimos-meses');

        setFaturamentoDia(responseDia.data.faturamento);
        setFaturamentoMes(responseMes.data.faturamento);
        setFaturamentoUltimosMeses(responseUltimosMeses.data);
      } catch (error) {
        console.error('Erro ao buscar dados de faturamento:', error);
      }
    };

    fetchData();
  }, []);

  const data = {
    labels: faturamentoUltimosMeses.map(item => item.mes),
    datasets: [
      {
        label: 'Faturamento',
        data: faturamentoUltimosMeses.map(item => item.valor),
        borderColor: '#ff6600',
        backgroundColor: 'rgba(255, 102, 0, 0.2)',
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="faturamento-container">
      <h1>Faturamento Líquido</h1>
      <div className="faturamento-card">
        <h2>Faturamento do Dia</h2>
        <p>R$ {faturamentoDia.toFixed(2)}</p>
      </div>
      <div className="faturamento-card">
        <h2>Faturamento do Mês</h2>
        <p>R$ {faturamentoMes.toFixed(2)}</p>
      </div>
      <div className="faturamento-chart">
        <h2>Análise dos Últimos Meses</h2>
        <Line data={data} options={{ responsive: true }} />
      </div>
    </div>
  );
};

export default Faturamento;
