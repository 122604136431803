import React from 'react';
import styles from './bemvindo.module.css'; // Importa o arquivo CSS Modules
import { Link } from 'react-router-dom';

const Welcome = () => {
    return (
        <section className={styles['body']}>
            <div className={styles['welcome-container']}>
                <h1>
                    <span style={{ color: '#e65100' }}>Boas-</span>
                    <span style={{ color: '#1a237e' }}>vindas</span> a Chama Seu Zé
                </h1>
                <div className={styles['button-container']}>
                    <Link to="/login" className={`${styles.btn} ${styles['blue-btn']}`}>Cliente</Link>
                    <Link to="/LoginP" className={`${styles.btn} ${styles['orange-btn']}`}>Prestador</Link>
                    <Link to="/Principal-Administrador" className={`${styles.btn} ${styles['orange-btn']}`}>Administrador</Link>
                </div>
            </div>
        </section>
    );
}

export default Welcome;
