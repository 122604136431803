// src/components/ServicesManagement.js

import React, { useState } from 'react';
import './ServicesManagement.css'; // Arquivo CSS para estilos

const ServicesManagement = () => {
  const [services, setServices] = useState([
    { id: 1, name: 'Reparo de Elétrica', category: 'Elétrica', averageValue: 'R$ 100,00' },
    { id: 2, name: 'Desentupimento', category: 'Encanamento', averageValue: 'R$ 150,00' },
    { id: 3, name: 'Reforma de Parede', category: 'Construção', averageValue: 'R$ 200,00' },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [newService, setNewService] = useState({ name: '', category: '', averageValue: '' });

  const handleInclude = () => {
    setShowModal(true);
  };

  const handleAddService = () => {
    if (!newService.name || !newService.category || !newService.averageValue) {
      alert('Preencha todos os campos.');
      return;
    }
    setServices([...services, { ...newService, id: services.length + 1 }]);
    setShowModal(false);
    setNewService({ name: '', category: '', averageValue: '' });
  };

  const handleEdit = () => {
    // Função para editar um serviço
    alert('Funcionalidade de edição não implementada.');
  };

  const handleDelete = () => {
    // Função para excluir um serviço
    alert('Funcionalidade de exclusão não implementada.');
  };

  return (
    <div className="services-management">
      <header>
        <h1>Gestão de Serviços</h1>
      </header>
      <main>
        <div className="header-buttons">
          <button onClick={handleInclude}>Incluir</button>
          <button onClick={handleEdit}>Alterar</button>
          <button onClick={handleDelete}>Excluir</button>
        </div>
        <section className="services-list">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Categoria</th>
                <th>Valor Médio</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr key={service.id}>
                  <td>{service.id}</td>
                  <td>{service.name}</td>
                  <td>{service.category}</td>
                  <td>{service.averageValue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Incluir Serviço</h2>
              <label>
                Nome:
                <input
                  type="text"
                  value={newService.name}
                  onChange={(e) => setNewService({ ...newService, name: e.target.value })}
                />
              </label>
              <label>
                Categoria:
                <input
                  type="text"
                  value={newService.category}
                  onChange={(e) => setNewService({ ...newService, category: e.target.value })}
                />
              </label>
              <label>
                Valor Médio:
                <input
                  type="text"
                  value={newService.averageValue}
                  onChange={(e) => setNewService({ ...newService, averageValue: e.target.value })}
                />
              </label>
              <div className="modal-buttons">
                <button onClick={handleAddService}>Adicionar</button>
                <button onClick={() => setShowModal(false)}>Cancelar</button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default ServicesManagement;
