import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';
import '@fortawesome/fontawesome-free/css/all.css';
import './mapapage.css';
import { useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';

const socket = io('https://appdev.chamaseuze.chamaoseuze.com.br/');

const MapaPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  const [userPosition, setUserPosition] = useState(null);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const pos = await getCurrentLocation();
        setUserPosition([pos.coords.latitude, pos.coords.longitude]);
      } catch (error) {
        console.error('Erro ao obter localização do usuário:', error.message);
      }
    };

    fetchUserLocation();
  }, []);

  useEffect(() => {
    socket.on('location-updated', (data) => {
      setProviders((prevProviders) => {
        const updatedProviders = prevProviders.map(provider =>
          provider.id === data.id ? { ...provider, latitude: data.latitude, longitude: data.longitude } : provider
        );
        return updatedProviders;
      });
    });

    const fetchProviders = async () => {
      try {
        const response = await fetch('https://appdev.chamaseuze.chamaoseuze.com.br/prestadores');
        const data = await response.json();
        setProviders(data);
      } catch (error) {
        console.error('Erro ao buscar prestadores:', error.message);
      }
    };

    fetchProviders();

    return () => {
      socket.off('location-updated');
    };
  }, []);

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error('Geolocalização não suportada pelo navegador.'));
      }
    });
  };

  useEffect(() => {
    if (userPosition) {
      if (mapInstance.current) {
        mapInstance.current.off();
        mapInstance.current.remove();
        mapInstance.current = null;
      }

      mapInstance.current = L.map(mapRef.current).setView(userPosition, 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      }).addTo(mapInstance.current);

      const userIcon = L.AwesomeMarkers.icon({
        icon: 'fa-location-arrow',
        markerColor: 'blue',
        prefix: 'fa'
      });

      L.marker(userPosition, { icon: userIcon }).addTo(mapInstance.current)
        .bindPopup('Você está aqui!')
        .openPopup();

      providers.forEach(provider => {
        if (provider.latitude && provider.longitude) {
          const providerIcon = L.AwesomeMarkers.icon({
            icon: 'fa-user',
            markerColor: 'green',
            prefix: 'fa'
          });

          L.marker([provider.latitude, provider.longitude], { icon: providerIcon }).addTo(mapInstance.current)
            .bindPopup(provider.nomeCompleto);
        } else {
          console.warn('Coordenadas inválidas para o prestador:', provider);
        }
      });
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.off();
        mapInstance.current.remove();
        mapInstance.current = null;
      }
    };
  }, [userPosition, providers]);

  function perfil() {
    navigate(`/ClienteProfile/${id}`);
  }

  function Agenda() {
    navigate(`/Agenda/${id}`);
  }

  function buscar() {
    navigate(`/Serviços/${id}`);
  }

  return (
    <div className="map-container">
      <div id="map" ref={mapRef} style={{ height: '90dvh' }}></div>
      <div className='content'>
        <button className='butn' onClick={Agenda}>
          <i className="fas fa-calendar-alt"></i> Agenda
        </button>
        <button className='butn' onClick={buscar}>
          <i className="fas fa-map-marker-alt"></i> Buscar Serviços
        </button>
        <button className='butn' onClick={perfil}>
          <i className="fas fa-user"></i> Perfil
        </button>
      </div>
    </div>
  );
};

export default MapaPage;
