import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './ProvidersManagement.css';
import axios from 'axios';

const ProvidersManagement = () => {
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);

  useEffect(() => {
    axios.get('https://appdev.chamaseuze.chamaoseuze.com.br/prestadores')
      .then(response => {
        setProviders(response.data);
      })
      .catch(error => {
        console.error('Erro ao buscar prestadores:', error);
        alert('Erro ao buscar prestadores. Verifique o console para mais detalhes.');
      });
  }, []);

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      axios.post('https://appdev.chamaseuze.chamaoseuze.com.br/prestadores/import', jsonData)
        .then(response => {
          setProviders(response.data);
        })
        .catch(error => {
          console.error('Erro ao importar prestadores:', error);
        });
    };
    reader.readAsArrayBuffer(file);
  };

  const handleEdit = () => {
    if (!selectedProvider) {
      alert('Selecione um prestador para editar.');
      return;
    }
    console.log(`Editar prestador com ID: ${selectedProvider.id}`);
  };

  const handleDelete = () => {
    if (!selectedProvider) {
      alert('Selecione um prestador para excluir.');
      return;
    }

    axios.delete(`https://appdev.chamaseuze.chamaoseuze.com.br/prestadores/${selectedProvider.id}`)
      .then(response => {
        setProviders(prevProviders => prevProviders.filter(provider => provider.id !== selectedProvider.id));
        setSelectedProvider(null);
      })
      .catch(error => {
        console.error('Erro ao excluir prestador:', error);
      });
  };

  const handleSelectProvider = (id) => {
    setSelectedProvider(providers.find((provider) => provider.id === id));
  };

  return (
    <div className="providers-management">
      <header>
        <h1>Gestão de Prestadores</h1>
      </header>
      <main>
        <div className="header-buttons">
          <label>
            <input
              type="file"
              accept=".xlsx"
              style={{ display: 'none' }}
              onChange={handleImport}
            />
            <button onClick={() => document.querySelector('input[type="file"]').click()}>
              Incluir
            </button>
          </label>
          <button onClick={handleEdit}>Alterar</button>
          <button onClick={handleDelete}>Excluir</button>
        </div>
        <section className="providers-list">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Serviço</th>
              </tr>
            </thead>
            <tbody>
              {providers.map((provider) => (
                <tr
                  key={provider.id}
                  className={selectedProvider?.id === provider.id ? 'selected' : ''}
                  onClick={() => handleSelectProvider(provider.id)}
                >
                  <td>{provider.id}</td>
                  <td>{provider.nomeCompleto}</td>
                  <td>{provider.servico}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default ProvidersManagement;
